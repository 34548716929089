import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubTitle from "../../components/SubTitle/SubTitle";
import { fetchMe } from "../../slices/user";
import Divider from "@mui/material/Divider";
import {
  getNotification,
  handleSetUnreadNotifications,
} from "../../slices/notifications";
import Sidebar from "./Sidebar";
import Header from "./Header";
import "./_index.scss";
import { handleNewLeave } from "../../slices/leave";
import { handleNewAuthorisation } from "../../slices/authorisation";
import { useWindowSize } from "../../hooks/useWindowDimensions";

const { io } = require("socket.io-client");
const socket = io(process.env.REACT_APP_SOCKET_PORT);

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  const [show, setSHow] = useState(true);
  const isSmallScreen = useWindowSize() <= 1544;

  const user = useSelector((state) => state.user.fetchMe);

  useEffect(() => {
    if (isSmallScreen) {
      setSHow(false);
    } else {
      setSHow(true);
    }
  }, [isSmallScreen]);

  useEffect(() => {
    if (user?.length === 0) dispatch(fetchMe());
  }, []);

  useEffect(() => {
    user && socket.emit("add_user", user?._id);
  }, [user]);

  useEffect(() => {
    socket.on("get_notifications", (data) => {
      dispatch(handleSetUnreadNotifications({ count: data }));
    });
  }, [socket, dispatch]);


  useEffect(() => {
    socket.on("check_notifications", () => {
      socket.emit("initial_notifications", user?._id);
    });
  }, [socket, user]);

  useEffect(() => {
    socket.on("check_list_leave", (data) => {
      dispatch(handleNewLeave(data));
    });
  }, [socket]);

  useEffect(() => {
    socket.on("check_list_authorisation", (data) => {
      dispatch(handleNewAuthorisation(data));
    });
  }, [socket]);

  socket.emit("initial_notifications", user?._id);

  return (
    <>
      <div className="main-layout">
        <>
          <Sidebar setSHow={setSHow} show={show} />
          <Divider orientation="vertical" flexItem />
        </>
        <div className="container">
          <Header show={show} setSHow={setSHow} user={user} />
          <div className="children_container">
            <SubTitle />
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
